import { Options, Vue } from "vue-class-component";
import { ILeave } from "@/types/Leave";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import moment from "moment";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import Filter from "@/components/Filter.vue";
import { IEmployeeList } from "@/types/EmployeeList";

// New book leaves
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Datepicker from "@vuepic/vue-datepicker";
import AuthStore from "@/store/auth-store";
import { eachDayOfInterval, isWeekend } from "date-fns";
import { computed } from "vue";
import store from "@/store";
import Toast from "@/components/Toast.vue";
import { notify } from "@/services/helpers";

@Options({
  name: "BookLeaves",
  components: {
    Toast,
    Breadcrumb,
    ConfirmationModal,
    PaginationComponent,
    Filter,
    Form,
    Field,
    ErrorMessage,
    Datepicker,
  },
  props: {},
  data: function () {
    return {
      loading: false,
      message: "",
      schema: yup.object().shape({
        startDate: yup.string().required("Please select a start date."),
        endDate: yup.string().required("Please select an end date."),
        reason: yup
          .string()
          .required("Please provide a reason.")
          .max(100, "Must not exceed 100 characters in length."),
        comment: yup
          .string()
          .max(200, "Must not exceed 200 characters in length."),
      }),
    };
  },
  methods: {},
})
export default class BookLeaves extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();

  protected authStore = AuthStore;

  isEditing = false;

  crumbs: any = ["Dashboard", "Leaves"];

  message = "";

  loading = false;

  $refs!: {
    confirmDialogue: HTMLFormElement;
    filter: HTMLFormElement;
    formEl: HTMLFormElement;
    toast: any;
  };

  leave: ILeave = {
    duration: 0,
    endDate: "",
    halfDayType: "",
    isHalfDay: false,
    isRostered: false,
    id: 0,
    comment: "",
    reason: "",
    startDate: "",
    status: "",
    employeeId: 0,
  };
  employeeName = "";
  employeeId = 0;
  showHalfDay = false;
  filterDate: any = ["", ""];
  isAdmin = true;

  authUser = computed(() => {
    return store.getters.authUser;
  });

  handleSubmit() {
    if (this.isEditing) {
      this.update();
    } else {
      this.save();
    }
  }

  showToast(msg: string, title = "Attention", type = "primary"): void {
    store.dispatch("showToast", {
      title: title,
      message: msg,
      type: type,
    });
  }

  tokenData = this.authStore.getTokenData();
  async created(): Promise<void> {
    await this.initialize();
  }

  /*
  +
  +
  +
  Edit Leaves ++++++++++++++++++++
  */
  async editLeaves(id: number) {
    this.isEditing = true;

    await this.workForceService
      .get<ILeave>(`/leave/${id}`, false)
      .then((response) => {
        if (response.content) {
          this.leave = response.content;

          if (
            this.leave.startDate.toString() === this.leave.endDate.toString()
          ) {
            this.showHalfDay = true;
          } else {
            this.showHalfDay = false;
          }

          this.filterDate[0] = this.leave.startDate;
          this.filterDate[1] = this.leave.endDate;
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      });
  }

  isCancellable(startDate: string, status: string) {
    if (this.formatDate(startDate) > this.formatDate(new Date())) {
      if (status == "PENDING" || status == "Pending") {
        return true;
      } else if (
        (status == "PENDING" || status == "Pending" || status == "APPROVED") &&
        this.isAdmin
      ) {
        return true;
      }
      return false;
    }
  }

  async update() {
    this.loading = true;
    this.message = "";

    if (!this.showHalfDay) {
      this.leave.halfDayType = "";
    }
    if (this.leave.halfDayType == "AM" || this.leave.halfDayType == "PM") {
      this.leave.isHalfDay = true;
    } else if (this.leave.halfDayType == "") {
      this.leave.isHalfDay = false;
    }
    this.leave.status = "Pending";
    this.workForceService
      .postOrPut<void>(
        `/leave/${this.leave.id}`,
        this.leave as ILeave,
        this.leave.id.toString()
      )
      .then(async (response: any) => {
        if (!response.isError) {
          this.loading = false;
          this.showToast(
            "Leave Request Updated Successfully",
            "Success",
            "success"
          );
          this.resetForm();
          this.initialize();
        } else if (response.data.rosterMessage) {
          const modal = await (this.$refs["confirmDialogue"] as any).show({
            title: "Warning",
            message: response.message,
            okButton: "Confirm leave",
            icon: "warning",
            type: "confirmation",
          });
          if (modal) {
            this.leave.isRostered = true;
            this.save();
          }
        } else {
          this.leave.isRostered = false;
          notify(response.errors, "Error", "danger");
        }
      });
  }

  /*
  +
  +
  +
  End edit leave ++++++++++++++++++++
  */

  /*
  +
  +
  +
  New Leaves ++++++++++++++++++++
  */
  resetForm() {
    this.leave.reason = "";
    this.leave.halfDayType = "";
    this.leave.comment = "";
    this.leave.startDate = "";
    this.leave.endDate = "";
    this.leave.status = "";
    this.leave.employeeId = 0;
    this.leave.id = 0;
    this.leave.duration = 0;

    this.filterDate = ["", ""];

    this.leave.isHalfDay = false;
    this.leave.isRostered = false;
    this.showHalfDay = false;
    this.leaveId = 0;

    this.isEditing = false;

    this.$refs.formEl.resetForm();
  }

  formatDate(date: any) {
    const nDate = new Date(date);
    const year = nDate.getFullYear();
    const month = nDate.getMonth() + 1;
    const day = nDate.getDate();
    return (
      year +
      "-" +
      (month.toString().length < 2 ? "0" + month : month) +
      "-" +
      (day.toString().length < 2 ? "0" + day : day)
    );
  }

  async handleDate(modelData: any) {
    this.leave.halfDayType = "";
    this.leave.startDate = this.formatDate(modelData[0]);
    this.leave.endDate = this.formatDate(modelData[1]);

    const daysInBetween = eachDayOfInterval({
      start: modelData[0],
      end: modelData[1],
    });

    const disabledDates = daysInBetween.filter((date) => !isWeekend(date))
      .length;

    this.leave.duration = disabledDates;

    if (this.leave.startDate.toString() === this.leave.endDate.toString()) {
      this.showHalfDay = true;
    } else {
      this.showHalfDay = false;
      this.leave.isHalfDay = false;
    }
  }

  async save() {
    this.loading = true;
    this.message = "";

    if (!this.showHalfDay) {
      this.leave.halfDayType = "";
    }
    if (this.leave.halfDayType == "AM" || this.leave.halfDayType == "PM") {
      this.leave.isHalfDay = true;
    } else if (this.leave.halfDayType == "") {
      this.leave.isHalfDay = false;
    }
    this.leave.status = "Pending";
    await this.workForceService
      .postOrPut<void>("/leave", this.leave as ILeave, undefined)
      .then(async (response: any) => {
        this.loading = false;
        if (!response.isError) {
          this.showToast(
            "Leave request sent for approval successfully.",
            "Success",
            "success"
          );
          this.resetForm();
          this.initialize();
        } else if (response.data.rosterMessage) {
          const modal = await (this.$refs["confirmDialogue"] as any).show({
            title: "Warning",
            message: response.message,
            okButton: "Confirm leave",
            icon: "warning",
            type: "confirmation",
          });
          if (modal) {
            this.leave.isRostered = true;
            this.save();
          }
        } else {
          this.leave.isRostered = false;
          notify(response.errors, "Error", "danger");
        }
      });
  }

  /*
  +
  +
  +
  End new leave ++++++++++++++++++++
  */

  /*
  +
  +
  +
  Time off history ++++++++++++++++++++
  */

  leaves: ILeave[] = [];
  page = 0;
  perPage = 10;
  totalCount = 0;
  currentPage = 0;

  get totalPages() {
    return Math.ceil(this.totalCount / this.perPage);
  }

  savedSort = null;
  async sort(sortBy: any) {
    this.savedSort = sortBy;
    return this.workForceService
      .get<IEmployeeList[]>(
        `/leave?EmployeeId=${this.employeeId}&SortBy=${sortBy}&PageIndex=${this.currentPage}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        this.leaves = response.content.items;
        this.totalCount = response.content.totalCount;
      });
  }

  goToPage(page: number) {
    this.page = page;
    return this.workForceService
      .get<ILeave[]>(
        `/leave?EmployeeId=${this.employeeId}&${
          this.savedSort ? "SortBy=" + this.savedSort + "&" : ""
        }PageIndex=${page}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        return (this.leaves = response.content.items);
      });
  }

  async refresh() {
    this.$refs.filter.resetFilter();
    await this.initialize();
  }

  async initialize() {
    const authId = (this.authUser as any).id;
    try {
      if (
        this.$route.params.id &&
        (this.tokenData.Roles == "Org Admin" ||
          this.tokenData.Roles == "Manager" ||
          this.tokenData.Roles == "Admin")
      ) {
        const currentId = parseInt(atob(this.$route.params.id.toString()));
        if (currentId != authId) {
          this.leave.employeeId = currentId;
        }
      }
      this.employeeId = parseInt(atob(this.$route.params.id.toString()));
      this.employeeName = atob(this.$route.params.name.toString());
    } catch (e) {
      this.$router.back();
    }

    if (this.tokenData.Roles == "Employee") {
      this.isAdmin = false;
    }

    this.workForceService
      .get<ILeave[]>(
        `/leave?EmployeeId=${this.employeeId}&PageIndex=${this.page}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        this.totalCount = response.content.totalCount;
        this.leaves = response.content.items;
      });
  }

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  formateDate(leaveDate: any) {
    return moment(new Date(leaveDate)).format("Do-MMM-YY");
  }

  leaveId = 0;
  async cancelLeave(id: number) {
    this.leaveId = id;
    const modal = await this.$refs.confirmDialogue.show({
      title: "Cancel Leave?",
      message:
        "Are you sure you want to Cancel this leave request? It cannot be undone.",
      okButton: "Cancel",
      cancelButton: "Go Back",
      theme: "danger",
      type: "confirmation",
    });
    if (modal) {
      await this.okClick();
      this.leaveId = 0;
    } else {
      this.leaveId = 0;
      return false;
    }
  }

  okClick() {
    this.workForceService
      .put<void>(`/leave/cancel/${this.leaveId}`, this.leaveId as number)
      .then((response: any) => {
        if (!response.isError) {
          this.showToast(
            "Leave request cancelled successfully.",
            "Success",
            "success"
          );
        } else {
          notify(response.errors, "Error", "danger");
        }
        this.initialize();
      });
  }
}
