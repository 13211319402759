<template>
  <!--  Breadcrumbs-->
  <Breadcrumb :crumbs="crumbs" />

  <!--  Content-->

  <div class="custom-container">
    <!--    New Leaves-->
    <!--    Page Title-->
    <page-title>
      <template #title>
        <span v-if="isEditing" class="h4 text-primary"
          >Edit Leave for
          <span class="text-primary-dark">{{ employeeName }}</span></span
        >
        <span v-else class="h4 text-primary"
          >Request Leave for
          <span class="text-primary-dark">{{ employeeName }}</span></span
        >
      </template>

      <template #button>
        <span>
          <button class="btn btn-secondary" @click="this.$router.back()">
            <font-awesome-icon class="mr-1" icon="arrow-left" />
            Go Back
          </button>
        </span>
      </template>
    </page-title>

    <div class="row pt-2">
      <div class="col-12">
        <Form
          @submit="handleSubmit"
          v-slot="meta"
          :validation-schema="schema"
          ref="formEl"
        >
          <!--            Leave Reason-->
          <div class="row">
            <div class="col-xs-12 col-md-6 form-group">
              <label>Leave Reason</label>
              <input-field icon="copy">
                <template v-slot:input>
                  <Field
                    type="text"
                    name="reason"
                    id="reason"
                    placeholder="Leave Reason"
                    :class="meta.errors.reason ? 'is-invalid' : ''"
                    v-model="leave.reason"
                  />
                  <ErrorMessage name="reason" class="invalid-feedback" />
                </template>
              </input-field>
            </div>
            <!--            Date-->
            <div class="col-xs-12 col-md-6 form-group">
              <label>Dates</label>
              <Datepicker
                uid="leaveDates"
                :inputClassName="
                  meta.errors.startDate
                    ? 'dp-lg shadow-sm dp-invalid'
                    : 'dp-lg shadow-sm'
                "
                placeholder="Please select a date"
                calendarCellClassName="dp-round-cell"
                menuClassName="dp-menu"
                autoApply
                v-model="filterDate"
                range
                format="dd/MM/yyyy"
                :clearable="false"
                :enableTimePicker="false"
                multiCalendars
                @update:modelValue="handleDate"
                :disabledWeekDays="[6, 0]"
                :minDate="new Date()"
              >
                <template #input-icon>
                  <span class="pl-3">
                    <font-awesome-icon
                      class="text-primary"
                      icon="calendar-check"
                    />
                  </span>
                </template>
              </Datepicker>
              <Field
                type="hidden"
                name="startDate"
                id="startDate"
                :class="meta.errors.startDate ? 'is-invalid' : ''"
                v-model="leave.startDate"
              />
              <ErrorMessage name="startDate" class="invalid-feedback" />
            </div>
          </div>

          <!--            Half Day-->
          <div v-if="showHalfDay" class="row">
            <div class="col-xs-12 col-md-6 form-group">
              <div class="">
                <label
                  :class="`btn btn-sm mr-2 ${
                    leave.halfDayType === ''
                      ? 'btn-primary'
                      : 'btn-outline-primary'
                  }`"
                >
                  <input
                    type="radio"
                    hidden
                    :value="''"
                    v-model="leave.halfDayType"
                    name="halfDayType"
                    autocomplete="off"
                  />
                  Full Day
                </label>

                <label
                  :class="`btn btn-sm mr-2 ${
                    leave.halfDayType === 'AM'
                      ? 'btn-primary'
                      : 'btn-outline-primary'
                  }`"
                >
                  <input
                    type="radio"
                    hidden
                    :value="'AM'"
                    v-model="leave.halfDayType"
                    name="halfDayType"
                    autocomplete="off"
                  />
                  Half Day AM
                </label>
                <label
                  :class="`btn btn-sm ${
                    leave.halfDayType === 'PM'
                      ? 'btn-primary'
                      : 'btn-outline-primary'
                  }`"
                >
                  <input
                    type="radio"
                    hidden
                    :value="'PM'"
                    v-model="leave.halfDayType"
                    name="halfDayType"
                    autocomplete="off"
                  />
                  Half Day PM
                </label>
              </div>
            </div>
          </div>

          <!--            Comment-->
          <div class="row">
            <div class="col-12">
              <label>Comment</label>
              <textarea
                name="comment"
                id="comment"
                :class="
                  meta.errors.comment
                    ? 'shadow-sm form-control is-invalid'
                    : 'shadow-sm form-control'
                "
                v-model="leave.comment"
                rows="5"
                placeholder="Add comments (optional)"
              ></textarea>
              <Field
                name="comment"
                :class="meta.errors.comment ? 'is-invalid' : ''"
                type="hidden"
                v-model="leave.comment"
              />
              <ErrorMessage
                name="comment"
                v-model="leave.comment"
                class="invalid-feedback"
              />
            </div>
          </div>

          <!--            Buttons-->
          <div class="row mb-5 mt-3 justify-content-center">
            <div
              class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group"
            >
              <button
                type="button"
                @click.prevent="resetForm"
                class="btn-secondary btn-lg-cus"
              >
                <span v-if="isEditing">Cancel</span>
                <span v-else>Reset</span>
              </button>
            </div>

            <div
              class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group"
            >
              <button
                type="submit"
                class="btn-primary btn-lg-cus"
                :disabled="loading"
              >
                <span v-if="isEditing"> Update </span>
                <span v-else> Send Request </span>
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
              </button>
            </div>
          </div>

          <div>
            <div
              v-if="message"
              v-html="message"
              class="alert alert-danger small"
            ></div>
          </div>
        </Form>
      </div>
    </div>

    <template v-if="!isEditing">
      <!--    History-->
      <page-title>
        <template #title>
          <span class="h4 text-primary">Time Off History</span>
        </template>

        <template #button>
          <div class="">
            <button class="btn btn-secondary" @click="refresh()">
              <font-awesome-icon class="mr-1" icon="history" />
              Refresh
            </button>
          </div>
        </template>
      </page-title>

      <!--  Filter-->
      <Filter
        class="mb-4"
        ref="filter"
        :sort-prop="[
          {
            key: 'Start Date',
            value: 'startDate',
          },
          {
            key: 'End Date',
            value: 'endDate',
          },
          {
            key: 'Duration',
            value: 'duration',
          },
          {
            key: 'Status',
            value: 'status',
          },
        ]"
        @apply-sort="sort($event)"
      ></Filter>

      <!--      Page Body-->
      <div class="row pt-2">
        <div class="col-12">
          <template v-if="leaves.length > 0">
            <template
              v-for="item in leaves"
              v-bind:key="item.id"
              v-bind:class="{
                'table-warning':
                  item.duration > 10 || item.isColleagueOnLeave === true,
              }"
            >
              <card-row :avatar="false">
                <!--        Details-->
                <template v-slot:details>
                  <div class="d-md-flex text-muted font-weight-bold">
                    {{ "Day(s): " + item.duration }}
                  </div>
                  <div class="d-md-flex text-muted">
                    <div class="mr-md-4">
                      {{ "From: " + formateDate(item.startDate) }}
                    </div>
                    <div>{{ "To: " + formateDate(item.endDate) }}</div>
                    <template v-if="item.halfDayType">{{
                      "(Half Day: " + item.halfDayType + ")"
                    }}</template>
                    <div v-if="item.rejectReason" class="ml-md-4">
                      Reject Reason: {{ item.rejectReason }}
                    </div>
                  </div>
                  <div class="text-muted">
                    Status:
                    <span
                      :class="`badge badge-pill badge-${
                        item.status == 'APPROVED'
                          ? 'success'
                          : item.status == 'CANCELLED'
                          ? 'secondary'
                          : item.status == 'REJECTED'
                          ? 'danger'
                          : 'warning'
                      }`"
                    >
                      {{ item.status }}
                    </span>
                  </div>
                </template>

                <!--        Actions-->
                <template v-slot:actions>
                  <button
                    v-if="item.status == 'PENDING' || item.status == 'Pending'"
                    class="btn btn-sm btn-primary px-2"
                    @click.prevent="editLeaves(item.id)"
                  >
                    <font-awesome-icon class="mr-1" icon="pencil-alt" />
                    Edit
                  </button>
                  <!--                    Cancel-->
                  <button
                    v-if="isCancellable(item.startDate, item.status)"
                    class="btn btn-sm btn-danger ml-1"
                    @click.prevent="cancelLeave(item.id)"
                  >
                    <font-awesome-icon class="mr-1" icon="trash" />
                    Cancel
                  </button>
                </template>
              </card-row>
            </template>
          </template>
          <template v-else>
            <div class="card mb-3 shadow-sm">
              <div class="card-body border-0 rounded py-3">
                <span class="h6">No content</span>
              </div>
            </div>
          </template>
        </div>

        <!--        Pagination-->
        <PaginationComponent
          v-if="totalCount > 10"
          :page-count="totalPages"
          :perPage="perPage"
          :click-handler="goToPage"
        ></PaginationComponent>
      </div>
    </template>
  </div>
  <ConfirmationModal ref="confirmDialogue"></ConfirmationModal>
</template>

<script src="./book-leaves.ts" />

<style scoped></style>
